@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  @apply text-primary
}

html{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, p {
  @apply my-2
}

h1{
  @apply text-xl md:text-4xl lg:text-6xl font-bold
}

h2{
  @apply text-sm md:text-xl font-medium text-secondary tracking-[.7rem]
}

h3{
  @apply text-xl md:text-2xl lg:text-4xl uppercase my-5 font-bold tracking-[.5rem]
}

h4{
  @apply text-xl font-medium text-primary tracking-[.7rem]
}

h5{
  @apply text-2xl font-medium
}

p{
  @apply text-sm hyphens-auto md:text-lg font-normal tracking-[.24rem] text-gray
}

a{
  @apply text-sm md:text-lg tracking-[.24rem] font-medium
}

.text-outline{
  -webkit-text-stroke-width: 0.07rem;
  -webkit-text-stroke-color: black;
  @apply text-transparent
}

.section{
  animation: swipe 8000ms linear infinite backwards;
}

@media only screen and (max-width: 600px) {
  .text-outline{
    -webkit-text-stroke-width: 0.05rem;
    -webkit-text-stroke-color: black;
    @apply text-transparent
  }
}
@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
